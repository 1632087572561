import React from 'react'
import { useEffect } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { trapFocus } from '../helpers/trapFocus'

const SiteHeader = ({ isHomePage, isMenuOpen, setMenuOpen }) => {

	const toggleMobileMenu = () => {
		setMenuOpen(isMenuOpen === true ? false : true)
	}

	useEffect(() => {
		if (isMenuOpen === true) {
			document.body.style.overflowY = 'hidden'
		} else {
			document.body.style.overflowY = 'auto'
		}

		const focusableElements = 'div, ul, li, a, [tabindex]:not([tabindex="-1"])';
		const menu = document.querySelector('#hamburger-menu');
		if (isMenuOpen) trapFocus(focusableElements, menu);
	}, [isMenuOpen])

	function onKeyUp(e){
		if (e.key === "Enter" || e.key === " ") toggleMobileMenu()
	}

	return (
		<div
			data-is-root-path={isHomePage}
			className="px-3 lg:px-6 py-5 bg-white border-b-[1px] border-b-ff_slateGray w-full z-40 fixed top-0"
		>
			<nav id="hamburger-menu" className="relative lg:flex items-center justify-between mx-auto">
				{isHomePage ? (
					<img
						src="/ff-logo.svg"
						alt="Fast Forward logo"
						className="w-16 xl:w-28"
					/>
				) : (
					<Link to="/" className="inline-block align-middle" tabIndex="1">
						<img
							src="/ff-logo.svg"
							alt="Fast Forward logo"
							className="w-16 xl:w-28"
						/>
					</Link>
				)}
				<div
					id="flyout-menu"
					aria-label="Main" 
					className={classNames(
						`absolute top-3 right-0 lg:hidden tham tham-e-squeeze tham-w-8`,
						{ 'tham-active': isMenuOpen },
					)}
					tabIndex="2"
					role="button"
					onKeyUp={onKeyUp}
					onClick={toggleMobileMenu}
					aria-expanded={isMenuOpen}
					aria-controls="flyout-menu"
				>
					<div className="tham-box">
						<div className="tham-inner" />
					</div>
				</div>

				<ul
					id="nav-header"
					className={isMenuOpen ? 'menu-open mobile-nav flex lg:desktop-nav bg-white z-20' : 'menu-closed mobile-nav flex lg:desktop-nav bg-white z-20'}
					aria-hidden={!isMenuOpen}
				>
					{[
						['Our Work', '/our-work', '', '3'],
						['Privacy Policy', '/privacy-policy', 'lg:hidden', ''],
						['Accessibility', '/accessibility', 'lg:hidden', ''],
					].map(([title, href, specialClasses, tabIndexNum], i) => (
						<li
							className={`mx-2 lg:mx-4 my-2 lg:my-0 inline-block text-xl xl:text-base ${specialClasses}`}
							key={`key-${i}`}
						>
							<Link
								className="text-ff_black hover-linear-gradient-underline"
								tabIndex={isMenuOpen ? "2" : tabIndexNum}
								to={href}
							>
								{title}
							</Link>
						</li>
					))}
					<li className=" ml-2 inline-block w-fit text-xl xl:text-base max-lg:hover-linear-gradient-underline lg:btn lg:ml-4 lg:align-middle lg:linear-gradient-background-hover">
						<Link
							className="text-ff_black lg:text-white" to="/contact-us"
							tabIndex={isMenuOpen ? "2" : "3"}
						>
							Contact Us
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default SiteHeader
