/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ description, title }) => {
	const { wp } = useStaticQuery(
		graphql`
			query {
				wp {
					generalSettings {
						title
						description
					}
				}
			}
		`,
	)

	const metaDescription = description || wp.generalSettings?.description
	const defaultTitle = wp.generalSettings?.title

	const titleTemplate = defaultTitle ? `${title} | ${defaultTitle}` : null

	return (
		<>
			{/* TODO- Find a way to pass it to the gatsby-ssr.js
			htmlAttributes={{
				lang,
			}} */}
			<title>{titleTemplate}</title>
			<meta name="description" content={metaDescription} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={metaDescription} />
			<meta property="og:type" content="website" />
			<meta
				name="theme-color"
				content="#000"
				media="(prefers-color-scheme: dark)"
			/>
			<meta
				name="theme-color"
				content="#FFF"
				media="(prefers-color-scheme: light)"
			/>
		</>
	)
}

export default Seo
