import React, { useState } from 'react'
import { graphql } from 'gatsby'

import SiteHeader from '../components/siteHeader'
import Footer from '../components/footer/footer'
import Seo from '../components/seo'
import { Link } from 'gatsby'

const NotFoundPage = ({ data, location }) => {
	const [isMenuOpen, setMenuOpen] = useState(false)

	return (
		<div className="relative top-[82px] lg:top-[92px] xl:top-[114px]">
			<div className="min-h-screen max-h-screen min-w-screen max-w-screen">
				<SiteHeader
					isHomePage={false}
					isMenuOpen={isMenuOpen}
					setMenuOpen={setMenuOpen}
				/>
				<main className="section main-section w-fit mx-auto">
					<span className="inline-block text-lg md:text-base">404 Error</span>
					<h1>Something's not right</h1>
					<div className="text-base">
						<p>
							We can't find the page you're looking for. Not to worry! Let's get
							you back on track.
						</p>
						<p>
							Our <Link to="/">homepage</Link> is a great place to start, or
							jump right into our latest <Link to="/our-work">work</Link>.
						</p>
						<p>
							You can always <Link to="/contact-us">contact us</Link> if you
							can't find what you're looking for.
						</p>
					</div>
				</main>
				<div className="radial-gradient-background-small">
					<Footer />
				</div>
			</div>
		</div>
	)
}

export default NotFoundPage

export function Head() {
	return <Seo title="404: Not Found" />
}

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
